import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    mapsContent:{  
        display: 'flex',
        flexDirection: 'column',    
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        backgroundColor: 'transparent',
        zIndex: '0',
        margin: '-25px 0px 25px 0px'
    },
    googleMapsBlock: {
        position: 'relative',
        height: '400px',
        width: '100%',
        margin: '20px 0px 0px 0px',
        zIndex: '0'
    },
    googleMapsCanvas: {
        overflow: 'hidden',
        background: 'none !important',
        height: '100%',
        width: '100%',
        zIndex: '0'
    }
  }));

//   <iframe width="520" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Pure%20Denture%20Clinic%20Edmonton+(Pure%20Denture%20Clinic)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> <a href='https://www.add-map.net/'>google maps widget wordpress</a> <script type='text/javascript' src='https://maps-generator.com/google-maps-authorization/script.js?id=da34dcdb136dfdb333014ebf39ad56f2167ce864'></script>
function GoogleMap(props) {
 
    const classes = useStyles();
    return (
        <div className={classes.mapsContent}>
            <div className={classes.googleMapsBlock}>
                <div className={classes.googleMapsCanvas}>
                    <iframe title="Pure Denture Clinic" width="100%" height="100%" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Pure%20Denture%20Clinic%20Edmonton+(Pure%20Denture%20Clinic)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" 
                    frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                    </iframe>
                </div>
            </div>  
        </div>        
    )
}

export default GoogleMap;