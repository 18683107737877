import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Hidden } from '@material-ui/core';
import { Email, LocationOn, Phone } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({    
  infoBar: {
    padding: '3px 2em',
    backgroundColor: '#F5F5F5',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    zIndex: theme.zIndex.appbar - 1,
  }, 
  color: {
    color: '#33A4DC',
    margin: '2px 5px 0px 0px',
    // paddingTop: '4px',
    fontSize: '1.2rem',
  },
  font: {
    fontSize: '0.85rem',
    marginRight: '2em',
  },
  margin: {
    marginRight: '2em',
  }
}));


function Infobar(props) {
    const classes = useStyles();
    return(
      // hide for mobile screens
        <Hidden xsDown>
          <div className={classes.infoBar}>
              <Email className={classes.color}/>
              <Typography className={classes.font} variant="subtitle1">
                {props.email}                  
              </Typography>
              <LocationOn className={classes.color}/>
              <Typography className={classes.font}  variant="subtitle1">
                {props.address}
              </Typography>
              <Phone className={classes.color}/>
              <Typography className={classes.font}  variant="subtitle1">
                {props.phone}
              </Typography>
          </div>
        </Hidden>
    )
} 

export default Infobar;