import React, {useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Dialog, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
import emailjs from 'emailjs-com';

const useStyles = makeStyles((theme) => ({
    container:{
    },
    button:{
        backgroundColor: 'red'
    },
    button2:{
        backgroundColor: 'green'
    }
  }));

function ContactForm(props) {    

    const classes = useStyles();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [emailHelper, setEmailHelper] = useState('');


    const [phone, setPhone] = useState('');
    const [phoneHelper, setPhoneHelper] = useState('');

    const [message, setMessage] = useState('');
    // const [messageHelper, setMessageHelper] = useState('');
    const [open, setOpen] = useState(false);
    const [ invalid , setInvalid ] = useState(true);

    function resetValues(){
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setMessage("");
    }

    const onChange = event => {
        let valid;

        switch(event.target.id){
            case 'emailAddress':
                setEmail(event.target.value);
                valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value);                

                if(!valid){
                    setEmailHelper('Invalid Email Address');
                    setInvalid(true);
                }else{
                    setEmailHelper('');
                    setInvalid(false);
                }
                break;
            case 'phoneNumber':
                setPhone(event.target.value);
                valid = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(event.target.value);                

                if(!valid){
                    setPhoneHelper('Invalid Phone Number');
                }else{
                    setPhoneHelper('');                    
                }
                break;
            default:
                break;
        };
    }

    emailjs.init("user_u81Bi5ShiKeNvHfRXQsnN");
    var templateParams = {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        email: email,
        message: message,
    };

    function SendEmail(e){
        e.preventDefault();
        emailjs.send('service_ibwijnw', 'template_n835inf', templateParams)
        .then(function(response) {
            // console.log('SUCCESS!', response.status, response.text);
            setOpen(true);
            resetValues();
        }, function(error) {
            console.log('FAILED...', error);
        });
        // e.target.reset();
    }

    return(
        <React.Fragment>
            <form onSubmit={SendEmail}>
                <Grid container spacing={2} className={classes.container}>
                    <Grid item xs={12} sm={6}>                    
                        <TextField 
                        fullWidth
                        id='firstName' 
                        label='First Name*' 
                        value={firstName} 
                        onChange={(event) => setFirstName(event.target.value)}
                        variant='outlined' />
                    {/* <input type="text" onChange={(event) => setFirstName(event.target.value)} value={firstName} /> */}

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                        fullWidth
                        id='lastName' 
                        label='Last Name*' 
                        value={lastName} 
                        onChange={(event) => setLastName(event.target.value)}
                        variant='outlined' />
                    </Grid>                  
                    <Grid item xs={12}>
                        <TextField 
                        fullWidth
                        id='emailAddress' 
                        label='Email Address*' 
                        value={email} 
                        onChange={onChange}
                        error={emailHelper.length !== 0}
                        helperText={emailHelper}
                        variant='outlined' />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                        fullWidth
                        id='phoneNumber' 
                        label='Phone Number*' 
                        value={phone} 
                        onChange={onChange}
                        error={phoneHelper.length !== 0}
                        helperText={phoneHelper}
                        variant='outlined' />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                        fullWidth
                        multiline
                        rowsMax={4}
                        rows={4}
                        id='messageBox' 
                        label='Message*' 
                        value={message} 
                        onChange={(event) => setMessage(event.target.value)}
                        variant='outlined' />
                    </Grid>
                    <Grid item xs={12} container direction="row"
                        align="left">
                        <Grid item xs={12}>
                            <Button disabled={invalid} type='submit' variant="contained" color="secondary" style={{ padding: '10px 65px', fontSize: '1.15rem'}}>Submit</Button>
                        </Grid>
                        <Dialog open={open} onClose={() => setOpen(false)}>
                            <DialogTitle>
                                <Typography variant='h3' color='secondary' align='center'>Message Submission</Typography>
                            </DialogTitle>
                            <DialogContent>
                                {/* <DialogContentText>
                                    Your message has been submitted.
                                </DialogContentText> */}
                                <Grid container direction="column">
                                    <Grid item xs={12}>
                                        <Typography variant='body1' align='center'>Your message has been submitted.</Typography>
                                    </Grid>
                                    <Grid item xs={12} align='center'>
                                        <Button variant="contained" color="secondary" onClick={() => setOpen(false)} style={{margin: '25px auto 15px auto'}}>Ok</Button>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    )
}

export default ContactForm;