import React, { useState, useEffect } from 'react';
import { Tabs, Tab, MenuItem, Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DrawerMenu from './DrawerMenu';
import BookButton from './BookButton';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({    
  customTabs: {
    marginLeft: 'auto',
  },
  tabContainer: {
    marginRight: '1em',
    padding: 'auto',
  },
  tabItem: {
    ...theme.typography.tab,
    opacity: '0.9'
  },
  tabItemSelected: {
    ...theme.typography.tab,
    color: theme.palette.secondary.main,
  },
  dropDown: {
    marginTop: theme.mixins.toolbar.minHeight,
    // pointerEvents: 'none',
  },
  dropDownItem: {
    padding: '10px 25px'
  },
}));


function CustomTabs(props) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
    const [anchorEl, setAnchorEl] = useState(null);  
    const history = useHistory();

    function handleClick() {
      history.push("/Services");
    }

    const handleHover = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setValue(2);
    };

    //hook for tab state 0 set for home
    const [ value, setValue ] = useState(0);
    //takes even and value being passed through(index of selected tab)
    const handleChange = (e, value) => {
      //use hook to change current tab to the selected tab value
      setValue(value);
      //this callback function resets the props.setZero boolean to false 
      props.resetValue();
    }

    function tabValue () {
      return ( props.setZero ? 0 : value );
    };

    function tabClassName (index) {
      return  tabValue() === index ? classes.tabItemSelected : classes.tabItem;
    }

    //active tab refresh fix
    useEffect(() => {
      if(window.location.pathname === "/" && value!== 0){
        setValue(0);
      }
      else if(window.location.pathname === "/AboutUs" && value!== 1){
        setValue(1);
      }
      else if(window.location.pathname === "/Services" && value!== 2){
        setValue(2);
      }
      else if(window.location.pathname === "/Complete" && value!== 2){
        setValue(2);
      }
      else if(window.location.pathname === "/Partial" && value!== 2){
        setValue(2);
      }
      else if(window.location.pathname === "/Immediate" && value!== 2){
        setValue(2);
      }
      else if(window.location.pathname === "/Implant" && value!== 2){
        setValue(2);
      }
      else if(window.location.pathname === "/Guards" && value!== 2){
        setValue(2);
      }
      else if(window.location.pathname === "/Repairs" && value!== 2){
        setValue(2);
      }
      else if(window.location.pathname === "/ContactUs" && value!== 3){
        setValue(3);
      }
    }, [value])

    const tabs = (
      <Tabs 
          value={tabValue()}
          onChange={handleChange}
          classes={{root: classes.tabContainer}}>

            {/* {tabsList} */}
            <Tab className={tabClassName(0)}
              component={Link} 
              to='/' 
              label='Home'
              key='Home'/>
            <Tab className={tabClassName(1)}
              component={Link} 
              to='/AboutUs' 
              label='About Us'
              key='About Us'/>
            {/* <ServiceDropDown/> */}
            <Tab className={tabClassName(2)}
              component={Link} 
              to="/Services"
              label='Services'
              key='Services' 
              // onMouseEnter={handleHover}
              // onClick={handleClick}
              // onMouseLeave= {handleClose}
              />

            <Menu
                className={classes.dropDown}
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                    <MenuItem className={classes.dropDownItem} component={Link} to='/Complete' onClick={handleClose}>Complete Dentures</MenuItem>
                    <MenuItem className={classes.dropDownItem} component={Link} to='/Partial' onClick={handleClose}>Partial Dentures</MenuItem>
                    <MenuItem className={classes.dropDownItem} component={Link} to='/Immediate' onClick={handleClose}>Immediate Dentures</MenuItem>
                    <MenuItem className={classes.dropDownItem} component={Link} to='/Implant' onClick={handleClose}>Implant Dentures</MenuItem>
                    <MenuItem className={classes.dropDownItem} component={Link} to='/Guards' onClick={handleClose}>Night / Mouth Guard</MenuItem>
                    <MenuItem className={classes.dropDownItem} component={Link} to='/Repairs' onClick={handleClose}>Reline / Repairs</MenuItem>
            </Menu>

            <Tab className={tabClassName(3)}
              component={Link} 
              to='/ContactUs' 
              label='Contact Us'
              key='Contact Us'/>
            <BookButton mobile={false} setValue={value}/>
      </Tabs>  
    )
    return(
        <div className={classes.customTabs}>
            { matches ? <DrawerMenu setValue={setValue} onChange={setValue} content={props.tabs}/> : tabs }
        </div>
    )
}

export default CustomTabs;