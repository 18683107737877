import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import aboutusImage from '../images/AboutUs2.jpg';
import bioImage from '../images/Bio1.jpg';
// import ImageCard from './ui/ImageCard';

const useStyles = makeStyles((theme) => ({
    // something here
    miniHeader:{
        minHeight: '25vh',    
        display: 'flex',
        flexDirection: 'column',    
        justifyContent: 'center',
        textAlign: 'center',
        [theme.breakpoints.down("xs")]: {            
            minHeight: '0', 
        } 
    },
    header: {
        ...theme.typography.contentHeader,
        margin: '30px 15px',
        textShadow: '2px 2px 15px rgba(0,0,0,0.8)',
        [theme.breakpoints.down("xs")]: {            
            fontWeight: '700',
            fontSize: '2.65rem',
        }     
    },
    subHeader:{
        ...theme.typography.contentHeader,
        margin: '30px 15px',
        textShadow: '2px 2px 15px rgba(0,0,0,0.8)',
        [theme.breakpoints.down("xs")]: {            
            fontWeight: '700',
            fontSize: '2.5rem',
        }
    },
    content:{
        minHeight: '20vh',    
        display: 'flex',
        flexDirection: 'column',    
        justifyContent: 'center',
        // textAlign: 'justify',
        backgroundColor: 'white',
        padding: '30px 20vw',
        [theme.breakpoints.down("xs")]: {            
            padding: '0px 25px 30px 25px',
        }   
    },
    content2:{
        minHeight: '40vh',    
        display: 'flex',
        flexDirection: 'row',    
        justifyContent: 'center',
        // textAlign: 'justify',
        // backgroundColor: 'rgb(235,235,235)',
        backgroundColor: 'white',
        padding: '30px 20vw',
        [theme.breakpoints.down("xs")]: {            
            padding: '10px 25px 30px 25px',
            flexWrap: 'wrap', 
        }  
    },
    header2: {
        ...theme.typography.contentHeader,
        margin: '25px auto',
        color: '#132E5B',
        [theme.breakpoints.down("xs")]: {            
            fontSize: '2.45rem',
        }
    },
    header3:{
        ...theme.typography.subContentHeader,
        fontSize: '2.35rem',
        color: '#132E5B'
    },
    header4:{
        color: 'rgba(0,0,0,0.75)',
        fontFamily: '"Montserrat", sans-serif',
        fontWeight: '700',
        fontSize: '1.75rem',
        margin: '0px 0px 15px 0px'
    },
    bioContent: {
        // margin: '20px 0px 0px 0px',
        textAlign: 'justify',
        [theme.breakpoints.up("sm")]: {            
            margin: '20px auto',            
        } 
    },
    bioText: {
        textShadow: '1px 1px 3px rgba(0,0,0,0.10)',     
        marginTop: '10px',  
        textAlign: 'left', 
    },
    profileImage: {
        padding: '20px 0px 0px 0px',
        margin: '0px 20px 0px 0px',
        height : 'auto', 
        width: '300px',
        [theme.breakpoints.down("xs")]: {            
            margin: '0px 0px 0px 0px',
        } 
    },
    clinicContent: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: '0px',
        maxWidth: '100%',
        [theme.breakpoints.down("xs")]: {            
            width: '100%',
        } 
    },
    clinicImage: {
        height: '450px',
        flexGrow: '1',
        objectFit: 'cover',
        margin: '0px 0px 25px 0px', 
        borderRadius: '3px',
        width: '50%',
        [theme.breakpoints.down("xs")]: {   
            height: '300px',
            maxWidth: '100%',   
            flexGrow: '1',
            objectPosition: '0 50%'
        }
    }
  }));

function AboutUs(props) {

    const classes = useStyles();
      
    return (
        <React.Fragment>
            <div className={classes.miniHeader}>
                <Typography className={classes.header}>About Us</Typography> 
            </div>    
            <div className={classes.content}>
                <Typography className={classes.header2} variant='h1'>Our Clinic</Typography>
                <div className={classes.clinicContent}>
                    
                <img className={classes.clinicImage} src={aboutusImage} alt='Pure Denture reception'/>
                    <Typography variant="body1">At Pure Denture Clinic, our vision of building a trusting and comfortable environment is vital to our clinic’s success. We are committed to providing affordable and high-quality care to allow you to smile with confidence. We are located in West Edmonton, just off the Anthony Henday.</Typography>
                </div>
            </div>
            <div className={classes.content2}>
                <div style={{alignItems: 'center'}}>
                    <img className={classes.profileImage} src={bioImage} alt='Denturist Andy Ko'></img>
                </div>
                <div className={classes.bioText}>
                    <Typography className={classes.header3}>ANDY KO, DD</Typography>
                        <Typography className={classes.header4}>Licensed Denturist</Typography>
                        <Typography variant='body1'>Andy completed the Denturist Technology program at Nait in 2015. He is an active member of the College of Alberta Denturist and the Association of Alberta Denturists. Andy is passionate about educating his patients and assisting them in regaining their confidence to smile. When Andy is not at the office, he enjoys traveling and playing sports. Andy can also speak Cantonese.
                    </Typography>
                </div>
            </div>
        </React.Fragment>        
    )
}

export default AboutUs;