import React from 'react';
import { Button, Hidden } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({    
    button: {
      marginLeft: '20px',
      [theme.breakpoints.down("xs")]: {
        marginLeft: '0px',
      }
    }
  }));

function BookButton(props){    
    const classes = useStyles();
    const history = useHistory();

    function handleClick(){
        history.push('/ContactUs');
        props.onChange(3);
    }
    return(
        // hide smDown or xsDown
        <Hidden xsDown={!props.mobile}>
            <Button className={classes.button} variant="contained" color="secondary" onClick={handleClick}>Book Appointment</Button>
        </Hidden>
    )
}

export default BookButton;