import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Home1 from '../../images/Home1.jpg';
import Home2 from '../../images/Home2.jpg';
import Home3 from '../../images/Home3.jpg';

const useStyles = makeStyles(theme => ({    
    collage: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: '0px',
        maxWidth: '100%',
        [theme.breakpoints.down("xs")]: {            
            width: '100%',
        } 
    },
    image: {
        height: '450px',
        flexGrow: '1',
        objectFit: 'cover',
        margin: '10px',
        borderRadius: '3px',
        // objectPosition: '0 0',
        [theme.breakpoints.down("xs")]: {   
            height: '250px',
            maxWidth: '100%',
            padding: '10px 25px 5px 25px',    
            margin: '0px',     
            flexGrow: '1',
        }
    },
    portraitImage: {
        height: '450px',
        flexGrow: '1',
        objectFit: 'cover',
        margin: '10px',
        borderRadius: '3px',
        [theme.breakpoints.down("xs")]: {   
            height: '375px',
            maxWidth: '100%',
            padding: '10px 25px 5px 25px',    
            margin: '0px',     
            flexGrow: '1',
        }
    },
  }));

function Collage(props){    
    const classes = useStyles();
    return(
        <div className={classes.collage}>
            <img className={classes.image} src={Home2} alt='Denture clinic reception' style={{ objectPosition: '20 0'}}></img>            
            <img className={classes.portraitImage} src={Home1} alt='Pure Denture clinic entrance' style={{ objectPosition: '10 0'}}></img>
            <img className={classes.image} src={Home3} alt='Denturist chair'></img>
        </div>
    )
}

export default Collage;