import React, { useState } from 'react';
import { Hidden, IconButton, SwipeableDrawer, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { Menu, Home, AccountBox, SettingsApplications, ContactMail } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import BookButton from './BookButton';
import ServiceDrawer from './ServiceDrawer';

const useStyles = makeStyles(theme => ({    
  BackdropProps: {
    background: 'rgba(0,0,0,0.1)',
    opacity: '0.2'
  },
  menu: {      
    fontSize: '1.75em',
    color: 'black',
    marginRight: '0.5em',
  },
  menuIcon: {
      color: theme.palette.primary.main
  },
  drawer: {
      position: 'fixed',
      backgroundColor: 'white',
      margin: '0',
      transition: 'width .35s ease-in-out'  
  },  
  drawerList: {
    //   ...theme.typography.tab
      ...theme.typography.drawerList
  },
  drawerListIcon: {
     height: '2em',
     width: '2em'
  }
}));

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

function DrawerMenu(props) {
    
    const classes = useStyles();
    const [drawer, setDrawer] = useState(false);
    const [ serviceValue, setServiceValue] = useState(false);

    function getListItemIcon (text) {

        const iconColor = "secondary";
        const iconClasses = classes.drawerListIcon;
        switch(text){
            case 'Home':
                return <Home color={iconColor} className={iconClasses}/>;
            case 'About Us':
                return <AccountBox color={iconColor} className={iconClasses}/>;
            case 'Services':
                return <SettingsApplications color={iconColor} className={iconClasses}/>;
            case 'Contact Us':
                return <ContactMail color={iconColor} className={iconClasses}/>;
            default:
                return null;
        }
    }

    let listContent = (
        props.content.map((listItem, index) => {

            if(listItem.text === 'Services'){
                return (
                    <React.Fragment key='services'>
                        <ListItem button divider component={Link} 
                            to={listItem.link} key={listItem.text} 
                            selected={props.value === index}
                            onClick={() => {
                                setServiceValue(!serviceValue);
                                }}>
                            <ListItemIcon>{getListItemIcon(listItem.text)}</ListItemIcon>
                            <ListItemText disableTypography>{listItem.text}</ListItemText>                    
                        </ListItem>  
                        <ServiceDrawer setDrawer={setDrawer} value={serviceValue}/>
                    </React.Fragment>
                )
            }
            return (
                <ListItem button divider component={Link} 
                    to={listItem.link} key={listItem.text} 
                    selected={props.value === index}
                    onClick={() => {
                        setDrawer(false); 
                        }}>
                    <ListItemIcon>{getListItemIcon(listItem.text)}</ListItemIcon>
                    <ListItemText disableTypography>{listItem.text}</ListItemText>                    
                </ListItem>  
            )            
        })
    );
    return(
        <React.Fragment>
            <Hidden>
                <IconButton className={classes.menu} onClick={() => setDrawer(true)} >
                    <Menu className={classes.menuIcon}/>
                </IconButton>
            </Hidden>
            <SwipeableDrawer 
                classes={{paper: classes.drawer}}         
                anchor="right"
                open={drawer}
                onClose={() => setDrawer(false)}   
                onOpen={() => setDrawer(true)}
                disableBackdropTransition={!iOS} 
                disableDiscovery={iOS}  
                ModalProps={{
                BackdropProps:{
                    classes:{
                    root:classes.BackdropProps
                    }
                }
                }}>
                <List className={classes.drawerList} disablePadding>
                {listContent}

                

                <ListItem divider onClick={() => setDrawer(false)}>
                    <BookButton onChange={props.onChange} mobile={true}/>
                </ListItem>                
                </List>                
            </SwipeableDrawer>
        </React.Fragment>
    )
}

export default DrawerMenu;