import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  palette: {
    primary: {
      light:'#445788',
      main: '#132E5B',
      dark: '##000331',
      contrastText: '#ffffff'
    },
    secondary: {
      light:'#73D5FF',
      main: '#33A4DC',
      dark: '#0075AA',
      contrastText: '#ffffff'
    },
    type: 'light'
  },
  typography: {
    fontFamily: '"Raleway", sans-serif',
    fontSize: '12px',
    fontWeightLight: '100',
    fontWeightRegular: '400',
    fontWeightBold: '700',
    button: {
      fontWeight: '700',
      fontSize: '1.0rem'
    },
    subtitle1: {
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: '400',
      fontSize: '0.65rem',
      color: '#696969'
    },
    subtitle2: {
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: '400',
      fontSize: '1.30rem',
      color: 'white'
    },
    body1: {
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: '400',
      fontSize: '1.20rem',
      color: 'black',
      [defaultTheme.breakpoints.down("xs")]: {            
        fontSize: '1.1rem',
    } 
    },
    body2: {
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: '500',
      fontSize: '1.30rem',
      color: 'white',
      [defaultTheme.breakpoints.down("xs")]: {            
        fontSize: '1.1rem',
    } 
    },
    contentHeader: {
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: '700',
      fontSize: '3.25rem',
      color: 'white',      
    },  
    subContentHeader:{
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: '700',
      fontSize: '2.50rem',
      color: 'white',   
    },  
    tab: {
      color: '#132E5B',
      fontWeight: '700',
      minWidth: '115px',
      backgroundColor: "transparent",
      "&:hover": {
          // color: '#33A4DC',
          opacity: '1'
        },         
    },
    drawerList: {
      fontWeight: '700',
      fontSize: '0.95rem',
      color: 'black',
    }
  },
});

export default theme;