import React from 'react';
import Header from './ui/Header';
import Footer from './ui/Footer';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, Fab } from '@material-ui/core';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Home';
import Services from './Services';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import theme from './ui/Theme';
import { useEffect } from "react";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ScrollTop from './ui/ScrollTop';
import ServicePage from './ui/ServicePage';

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const anchor = (document).querySelector('#back-to-top-anchor');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'auto', block: 'center' });
    }
  }, []);

  return null;
}

function App() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <ThemeProvider theme={theme}> 
      <CssBaseline>
        <BrowserRouter>
          <Header />
          <ScrollTop>
              <Fab color="secondary" size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon />
              </Fab>
          </ScrollTop>
          <Switch>
            <Route 
            exact 
            path="/" 
            component={() => 
            <React.Fragment>
              <Home/> 
              <ScrollToTopOnMount/>
            </React.Fragment>}/>
            <Route exact path="/AboutUs" component={() => 
            <React.Fragment>
              <AboutUs/>
              <ScrollToTopOnMount/>
            </React.Fragment>}/>
            <Route exact path="/Services" component={() => 
            <React.Fragment>
              <Services/>              
              <ScrollToTopOnMount/>
            </React.Fragment>}/>
            <Route exact path="/ContactUs" component={() => 
            <React.Fragment>
              <ContactUs/>              
              <ScrollToTopOnMount/>
            </React.Fragment>}/>

            {/* Individual Service Pages */}
            <Route path='/Complete' exact component={() =>               
              <React.Fragment>
                <ServicePage variant='complete'/>
                <ScrollToTopOnMount/>
              </React.Fragment>
            }/>  
            <Route path='/Partial' exact component={() => 
              <React.Fragment>
                <ServicePage variant='partial'/>
                <ScrollToTopOnMount/>
              </React.Fragment>
            }/> 
            <Route path='/Immediate' exact component={() => 
              <React.Fragment>
                <ServicePage variant='immediate'/>
                <ScrollToTopOnMount/>
              </React.Fragment>
            }/> 
            <Route path='/Implant' exact component={() => 
              <React.Fragment>
                <ServicePage variant='implant'/>
                <ScrollToTopOnMount/>
              </React.Fragment>
            }/> 
            <Route path='/Guards' exact component={() => 
              <React.Fragment>
                <ServicePage variant='guards'/>
                <ScrollToTopOnMount/>
              </React.Fragment>
            }/> 
            <Route path='/Repairs' exact component={() => 
              <React.Fragment>
                <ServicePage variant='repair'/>
                <ScrollToTopOnMount/>
              </React.Fragment>
            }/> 
          </Switch>
          
          <Footer/>
        </BrowserRouter>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
