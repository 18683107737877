import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import ImageCard from './ui/ImageCard';
import completeImage from '../images/Complete2.jpg';
import partialImage from '../images/Partial2.jpg';
import immidiateImage from '../images/Immidiate2.jpg';
import implantImage from '../images/Implant.jpg';
import mouthGuardImage from '../images/NightGuard2.jpg';
import repairImage from '../images/Repair2.jpg';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    miniHeader:{
        minHeight: '25vh',    
        display: 'flex',
        flexDirection: 'column',    
        justifyContent: 'center',
        textAlign: 'center',
        [theme.breakpoints.down("xs")]: {            
            minHeight: '0', 
        } 
    },
    header: {
        ...theme.typography.contentHeader,
        margin: '30px 15px',
        textShadow: '2px 2px 15px rgba(0,0,0,0.8)',
        [theme.breakpoints.down("xs")]: {            
            fontWeight: '700',
            fontSize: '2.65rem',
        }     
    },
    content:{
        minHeight: '40vh',    
        display: 'flex',
        flexDirection: 'row',  
        flexWrap: 'wrap',  
        justifyContent: 'center',
        // textAlign: 'justify',
        backgroundColor: 'white',
        // transition: 'ease 500',
        padding: '30px 20vw',
        [theme.breakpoints.down("xs")]: {            
            padding: '0px 25px 20px 25px',
        }  
    },
    header2: {
        ...theme.typography.contentHeader,
        margin: '25px auto',
        color: '#132E5B',
        [theme.breakpoints.down("xs")]: {     
            fontSize: '2.45rem',

        } 
    },
    bioContent: {
        margin: '20px auto'
    }
  }));

function Services(props) {

    const classes = useStyles();
    
    return (
        <React.Fragment>
            <div className={classes.miniHeader}>
                <Typography className={classes.header}>Services</Typography> 
            </div>                 
            <div className={classes.content}>       
                <Link to='/Complete' style={{ textDecoration: 'none'}}>
                    <ImageCard 
                        alt="Complete Dentures"
                        header='Complete Dentures' 
                        url={completeImage}>
                        Replacement when all natural teeth are missing
                    </ImageCard>
                </Link> 
                <Link to='/Partial' style={{ textDecoration: 'none'}}>
                    <ImageCard 
                        alt="Partial dentures"
                        header='Partial Dentures' 
                        url={partialImage}>
                            Replacement when one or more teeth are missing
                    </ImageCard>
                </Link>
                <Link to='/Immediate' style={{ textDecoration: 'none'}}>
                    <ImageCard 
                        alt="Immediate dentures"
                        header='Immediate Dentures' 
                        url={immidiateImage}>
                            Replacement used when transitioning from having natural teeth to missing all natural teeth
                    </ImageCard>
                </Link>
                <Link to='/Implant' style={{ textDecoration: 'none'}}>
                    <ImageCard 
                        alt="Implant dentures"
                        header='Implant Retained Dentures' 
                        url={implantImage}>
                            Replacement used for more precise and supported fit
                    </ImageCard>
                </Link>
                <Link to='/Guards' style={{ textDecoration: 'none'}}>   
                     <ImageCard 
                        alt="Night guards"
                        header='Night Guards / Mouth Guards' 
                        url={mouthGuardImage}>
                            Device to help protect teeth from grinding and clenching
                    </ImageCard>                 
                </Link>
                <Link to='/Repairs' style={{ textDecoration: 'none'}}>    
                    <ImageCard 
                        alt="Denture repair"
                        header='Relines / Repairs' 
                        url={repairImage}>
                            Adjusting dentures for a better fit
                    </ImageCard>                
                </Link>
            </div>
        </React.Fragment>  
    )
}

export default Services;