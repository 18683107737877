import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    footer: {
        // backgroundColor: 'rgba(19,46,91,0.95)',
        backgroundColor: 'rgba(19,46,91,0.75)',

        width: '100%',
        padding: '0px 20vw',
        [theme.breakpoints.down("xs")]: {            
            padding: '0px 0px 0px 0px',
        }  
      },
      header: {
        fontFamily: '"Montserrat", sans-serif',
        fontWeight: '700',
        fontSize: '1.75rem',
        color: '#33A4DC',
        margin: '10px 0px',
        alignItems: 'left'
      },
      paragraph: {
          fontFamily: '"Montserrat", sans-serif',
          color: 'white',
          fontSize: '1.2rem',
          padding: '5px 0px',
          textAlign: 'left',
          [theme.breakpoints.down("xs")]: {            
            fontSize: '1rem',
        } 
      },
      table: {
          fontFamily: '"Montserrat", sans-serif',
          color: 'white',
          textAlign: 'left',
          padding: '0px 20px',
          [theme.breakpoints.down("xs")]: {            
            fontSize: '1rem',
        }  
      },
      tableRowItems: {
          fontWeight: '400',
          padding: '5px 0px 5px 0px',
          width: '50%',

      },
      infoTop:{
        padding: '1em 0px 1em 0px',
      },
      copyright: {
          padding: '15px 0px',
          textAlign: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(19,46,91,0.85)',   
          [theme.breakpoints.down("xs")]: {            
            padding: '10px 0px',
        }        
      },
      copyrightText: {
        fontSize: '1.0rem',
        [theme.breakpoints.down("xs")]: {            
            fontSize: '0.55rem',
            textAlign: 'center',
        } 
      }
  }));

const schedule = [
    {
        day: 'Monday',
        time: '9:00am - 5:00pm'
    },
    {
        day: 'Tuesday',
        time: '9:00am - 5:00pm'
    },
    {
        day: 'Wednesday',
        time: '9:00am - 5:00pm'
    },
    {
        day: 'Thursday',
        time: '9:00am - 5:00pm'
    },
    {
        day: 'Friday',
        time: '9:00am - 5:00pm'
    },
    {
        day: 'Saturday',
        time: 'Closed'
    },
    {
        day: 'Sunday',
        time: 'Closed'
    },
    // {
    //     day: 'Evenings and Saturdays',
    //     time: 'by Appointment'
    // },
]

// const information = [
//     "18333 Lessard Rd NW",
//     "Edmonton, AB T6M 2W8",
//     "Phone: 780-477-6850",
//     "Email: puredenture@gmail.com"
// ]

function Footer(){
    const classes = useStyles();

    let scheduleTable = (
        <table className={classes.table}>
            <tbody>
                {
                    schedule.map((items, index) => 
                    <tr key={index}>
                        <th className={classes.tableRowItems}>
                            {items.day + ":"}
                        </th>
                        <td className={classes.tableRowItems}>
                            {items.time}
                        </td>
                    </tr>)
                }
                <tr>
                    <td colSpan='2' style={{fontWeight: '400',
                        padding: '5px 0px 5px 0px',}}>
                        {"Evenings and Saturdays by Appointment"}
                    </td>
                </tr>
            </tbody>
        </table>        
    );
    // let informationTable = (
    //     information.map(items =>
    //         <Typography className={classes.paragraph}>{items}</Typography>)
    // );
    return(
        <React.Fragment>
            <footer className={classes.footer}>
                <Grid container direction="column">
                    <Grid item container
                        className={classes.infoTop}
                        direction="row"
                        justify="space-evenly"
                        alignItems="flex-start">
                        <Grid item sm={6} container direction="column" alignItems="center"> 
                            <Grid item>
                                <Typography className={classes.header}>Information</Typography>
                                {/* {informationTable} */}
                                <Typography className={classes.paragraph}>18336 Lessard Rd NW</Typography>
                                <Typography className={classes.paragraph}>Edmonton, AB T6M 2W8</Typography>
                                <Typography className={classes.paragraph}><a href='tel:7804776850' style={{textDecoration: 'none', color: 'inherit'}}>780-477-6850</a></Typography>
                                <Typography className={classes.paragraph}><a href='mailto:puredenture@gmail.com' style={{textDecoration: 'none', color: 'inherit'}}>Email: puredenture@gmail.com</a></Typography>
                            </Grid>
                        </Grid>
                        <Grid item sm={6} container direction="column" alignItems="center">
                        <Typography className={classes.header}>Hours Of Operation</Typography>
                        {scheduleTable}
                        </Grid>
                    </Grid>
                </Grid>            
            </footer>
            <div className={classes.copyright}>                    
                <Typography className={classes.copyrightText} variant='subtitle2'>Copyright Pure Denture Clinic © 2020 All Rights Reserved.</Typography>
            </div>
        </React.Fragment>
    )
}

export default Footer;