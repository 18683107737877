import React , { useState } from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { makeStyles } from '@material-ui/styles';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import CustomTabs from './CustomTabs';
import LogoButton from './LogoButton';
import Infobar from './Infobar';

function ElevationScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }

const useStyles = makeStyles((theme) => ({
  appbar: {
    top: '0px',
    padding: '0px 20vw',
    backgroundColor: '#FFFFFF',
    transition: 'all 75ms ease',
    [theme.breakpoints.down("xs")]: {
      padding: '0px 0px 0px 0.5em'
    }
  },
  appbarMarginTop: {
    top: '28px',
    padding: '0px 20vw',
    backgroundColor: '#FFFFFF',
    transition: 'all 125ms linear',
    [theme.breakpoints.down("xs")]: {
      top: '0px',
      padding: '0px 0px 0px 0.5em'
    }
  },
  marginBar: {
    minHeight: theme.mixins.toolbar.minHeight - 28,
    backgroundColor: '#F5F5F5',
    marginBottom: '50px',
  },
}));

const tabsList = [
    {
      text: 'Home',
      link: '/'
    },
    {
      text: 'About Us',
      link: '/AboutUs'
    },
    {
      text: 'Services',
      link: '/Services'
    },
    {
      text: 'Contact Us',
      link: '/ContactUs'
    }
];

function Header(props) {
    const classes = useStyles();
    const [ value, changeState ] = useState(false);        
    const [ scrollTop, toggleMargin ] = useState(true);

    useScrollPosition(({ currPos }) => {
      currPos.y < 0 ? toggleMargin(false) : toggleMargin(true);
    })
    
    const logoClick = (e, value) => {
      changeState(true);
    } 

    function resetValue(){    
      changeState(false);
    }

    return (
        <React.Fragment>   
              <Infobar email="puredenture@gmail.com" address="18336 Lessard Rd NW Edmonton AB" phone="(780)477-6850"/>
              <Toolbar id="back-to-top-anchor" className={classes.marginBar}/>
            <ElevationScroll>
                <AppBar position="fixed" className={ scrollTop ? classes.appbarMarginTop : classes.appbar} >
                    <Toolbar disableGutters>
                      <LogoButton setZero={logoClick}/>
                      <CustomTabs  resetValue={resetValue} setZero={value} tabs={tabsList} />
                      {/* <BookButton mobile={false}/> */}
                    </Toolbar>                    
                </AppBar>
            </ElevationScroll>
        </React.Fragment>
    )
}

export default Header;