import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({       
  image: {
      height: '190px',
      width: '100%',
      borderRadius: '4px',
      [theme.breakpoints.down("xs")]: {  
        height: '165px',
        width: '100%',
    }
  }, 
  card: {
      backgroundColor: 'white',
      padding: '25px 20px',
      width: '300px',
      minHeight: '425px',
      borderRadius: '4px',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.4)',
      transition: '0.3s',
      textAlign: 'center',
      margin: '20px 20px',
      textDecoration: 'none',
      '&:hover' :{
        boxShadow: '0 4px 12px 0 rgba(19, 46, 91,0.75)',
      },
      [theme.breakpoints.down("xs")]: {  
        margin: '20px 0px 10px 0px',
        minHeight: '275px',
        // maxHeight: '350px',
        fontSize: '1.0rem',
    }
  },
  header: {
      fontWeight: 'bold',
      padding: '0.4em 0px'
  }
}));

function ImageCard(props) {
    const classes = useStyles();
    // function resetValue(){    
    //     props.setHidden(false);
    //   }
    return(        
        <div href='' className={classes.card} >
            <Grid container
                direction="column"
                justify="center"
                alignItems="center">
                <Grid item xs={12}>
                    <img className={classes.image} src={props.url} alt={props.alt}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography color='primary' variant="h3" gutterBottom className={classes.header}>{props.header}</Typography>
                    <Typography variant='body1'>{props.children}</Typography>
                </Grid>
            </Grid>    
        </div>
    )
} 

export default ImageCard;