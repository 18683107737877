import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import ContactForm from './ui/ContactForm';
import GoogleMap from './ui/GoogleMap';

const useStyles = makeStyles((theme) => ({
    // something here
    miniHeader:{
        minHeight: '25vh',    
        display: 'flex',
        flexDirection: 'column',    
        justifyContent: 'center',
        textAlign: 'center',
        [theme.breakpoints.down("xs")]: {            
            minHeight: '0', 
        } 
    },
    headerText: {
        ...theme.typography.contentHeader,
        margin: '30px 15px',
        textShadow: '2px 2px 15px rgba(0,0,0,0.8)',
        [theme.breakpoints.down("xs")]: {            
            fontWeight: '700',
            fontSize: '2.65rem',
        }       
    },
    content:{
        minHeight: '40vh',    
        display: 'flex',
        flexDirection: 'column',    
        justifyContent: 'center',
        // textAlign: 'justify',
        backgroundColor: 'white',
        padding: '30px 20vw',
        [theme.breakpoints.down("xs")]: {            
            padding: '0px 25px 20px 25px',
        }  
    },
    header2: {
        ...theme.typography.contentHeader,
        margin: '25px auto',
        color: '#132E5B',
        [theme.breakpoints.down("xs")]: {     
            fontSize: '2.45rem',

        } 
    },
  }));

function ContactUs(props) {

    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.miniHeader}>
                <Typography className={classes.headerText}>Contact Us</Typography> 
            </div>  
            <div className={classes.content}>                
                <Typography className={classes.header2} variant='h1'>Our Location</Typography>                
                <GoogleMap/>
                <Typography className={classes.header2} variant='h1'>Message Us</Typography>
                <ContactForm/>
            </div>
        </React.Fragment>
        
    )
}

export default ContactUs;