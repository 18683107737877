import React from 'react';
import { List, ListItem, ListItemText, Grow } from '@material-ui/core';
import { Link } from 'react-router-dom';

function ServiceDrawer(props) {
    
    return(
        <React.Fragment>
            <Grow in={props.value}>
                <List style={{ display: props.value ? 'block' : 'none'}} >
                    <ListItem button divider 
                        component={Link} 
                        to='/Complete' 
                        key='Complete'
                        onClick={() =>{
                            props.setDrawer(false);}}>                
                        <ListItemText disableTypography>Complete Dentures</ListItemText>
                    </ListItem>
                    <ListItem button divider 
                        component={Link}
                        to='/Partial' 
                        key='Partial'
                        onClick={() =>{
                            props.setDrawer(false);}}>                
                        <ListItemText disableTypography>Partial Dentures</ListItemText>
                    </ListItem>
                    <ListItem button divider 
                        component={Link}
                        to='/Immediate' 
                        key='Immediate'
                        onClick={() =>{
                            props.setDrawer(false);}}>                
                        <ListItemText disableTypography>Immediate Dentures</ListItemText>
                    </ListItem>
                    <ListItem button divider 
                        component={Link}
                        to='/Implant'
                        key='Implant' 
                        onClick={() =>{
                            props.setDrawer(false);}}>                
                        <ListItemText disableTypography>Implant Dentures</ListItemText>
                    </ListItem>
                    <ListItem button divider 
                        component={Link}
                        to='/Guards'
                        key='Guards' 
                        onClick={() =>{
                            props.setDrawer(false);}}>                
                        <ListItemText disableTypography>Night / Mouth Guards</ListItemText>
                    </ListItem>
                    <ListItem button divider 
                        component={Link}
                        to='/Repairs'
                        key='Repairs'
                        onClick={() =>{
                            props.setDrawer(false);}}>                
                        <ListItemText disableTypography>Reline / Repairs</ListItemText>
                    </ListItem>
                </List>
            </Grow>
        </React.Fragment>
    )
}

export default ServiceDrawer;