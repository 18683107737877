import React , { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import completeImage from '../../images/Complete2.jpg';
import partialImage from '../../images/Partial2.jpg';
import immidiateImage from '../../images/Immidiate2.jpg';
import implantImage from '../../images/Implant.jpg';
import mouthGuardImage from '../../images/NightGuard2.jpg';
import repairImage from '../../images/Repair2.jpg';

const useStyles = makeStyles((theme) => ({
    content:{
        minHeight: '40vh',    
        display: 'flex',
        flexDirection: 'column',    
        justifyContent: 'center',
        // textAlign: 'justify',
        backgroundColor: 'white',
        padding: '30px 20vw',
        [theme.breakpoints.down("xs")]: {            
            padding: '30px 25px 30px 25px',
        }  
    },
    header3:{
        ...theme.typography.subContentHeader,
        color: '#132E5B',   
        marginBottom: '0px',  
        [theme.breakpoints.down("xs")]: {    
            fontSize: '2.0rem',
            lineHeight: '42px', 
        }    
    },
    header4:{
        color: 'rgba(0,0,0,0.75)',
        fontFamily: '"Montserrat", sans-serif',
        fontWeight: '700',
        fontSize: '1.75rem',
        margin: '0px 0px 25px 0px',
        [theme.breakpoints.down("xs")]: {    
            fontSize: '1.10rem',
        }  
    },
    header5 : {
        fontSize: '1.30rem',
        fontWeight: '500',
        marginBottom: '0px',
        [theme.breakpoints.down("xs")]: {            
            fontWeight: '400',
            fontSize: '1.20rem',
        } 
    },
    miniHeader:{
        minHeight: '25vh',    
        display: 'flex',
        flexDirection: 'column',    
        justifyContent: 'center',
        textAlign: 'center',
        [theme.breakpoints.down("xs")]: {            
            minHeight: '0', 
        } 
    },
    header: {
        ...theme.typography.contentHeader,
        margin: '30px 15px',
        textShadow: '2px 2px 15px rgba(0,0,0,0.8)',
        [theme.breakpoints.down("xs")]: {            
            fontWeight: '700',
            fontSize: '2.5rem',
        }     
    },
    imageDiv: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'center',
        [theme.breakpoints.down("xs")]: {            
            flexWrap: 'wrap', 
        }     
    },
    image: {
        margin: '20px 30px',
        height: 'auto',
        width: '250px',
        [theme.breakpoints.down("xs")]: {            
            margin: '0', 
            height: 'auto',
             width: '100%',
        }   
    },
    body: {
        margin: '25px 0px',
        // [theme.breakpoints.down("xs")]: {            
        //     margin: '25px 0px', 
        // }   
    }
  }));

// service page contents
const serviceList = {
    complete : {
      title : 'Complete Dentures',
      caption: '(Replacement when all natural teeth are missing)',
      body : "Complete dentures are removable replacements when all your natural teeth are missing. These dentures can be made for the upper and/or lower arch of the mouth. Complete dentures rest on the gum tissues and are held in by suction. They are constructed to provide proper fit and function while maintaining a natural appearance. Our dentures are custom-fitted to give you a more personalized smile. We offer different grades of complete dentures that may be necessary to meet your expectations and treatment needs.",
      footer : 'Schedule a complimentary consultation today to find out more about which      treatment solution is best suited for you.',
    },
    partial : {
      title : 'Partial Dentures',
      caption: '(Replacement when one or more teeth are missing)',
      body : 'Partial dentures are removable dental appliances used to restore one or more missing teeth. Over time, spaces with missing teeth can cause the existing teeth beside these spaces to start to shift or drift. Partial dentures can prevent these movements from occurring. These dentures can help restore a natural appearance, improve chewing and provide support for the remaining natural teeth. They can be made of acrylic when used as a temporary replacement. When used as a more long-term solution, partial dentures are fabricated with a metal frame made out of titanium or chrome-cobalt to provide more strength and durability. We offer different types of partial dentures to meet different expectations and treatment needs.',
      footer : 'Call us today to learn more about our different partial denture options.',
    },
    immediate : {
      title : 'Immediate Dentures',
      caption: '(Replacement used when transitioning from having natural teeth to missing all natural teeth)',
      body : 'Immediate dentures are also sometimes known as surgical dentures. These removable appliances are used when patients are transitioning from having some or all of their natural teeth to no longer having teeth. Immediate dentures are made based on existing esthetics prior to having natural teeth extracted. They are inserted the same day your natural teeth are extracted to help avoid being without teeth for a long period time. This will also act as a band-aid in controlling the bleeding and swelling while the gums are healing. As the gums heal, we will put temporary liners to stabilize the dentures. These temporary liners will be needed for about 9-12 months after the extractions. Once the gums have properly healed, the tissue surface of dentures will need to be relined for a proper re-fit.',
      footer : 'Contact us today to see if immediate dentures are the right treatment for you.',      
    },
    implant : {
      title : 'Implant Retained Dentures',
      caption: '(Replacement used for more precise and supported fit)',
      body : 'Implant retained dentures are created to provide a more retentive and stable fit. Dental implants are used as an anchor for the denture. By providing additional support for the denture to minimize movement, it will increase the overall comfort and allow you to chew your food better. In comparison to conventional dentures, implant-retained dentures have better functioning and fit.',
      listTitle : 'Benefits of Implant Dentures:',
      list : [
                '-Increased retention and comfort',
                '-Better chewing ability',
                '-Improved stability and function',
                '-More natural look and feel',
                '-Improved speech',
                '-Minimizes bone loss and resorption',
      ],
      footer : 'Book an appointment today to find out more information about our implant denture options.',
    },
    guards : {
      title : 'Night Guards',
      caption: '(Device to help protect teeth from grinding and clenching)',
      body : 'Night guards are removable devices that help protect your teeth from grinding and clenching at night while you sleep. Many individuals suffer from a condition called "bruxism", also known as teeth grinding. Night guards can help in relieving the wear of teeth, jaw pain and jaw stiffness.',
      title2 : 'Mouth Guards',
      body2 : 'Mouth guards are custom fitted over your teeth to help protect and reduce injury to your teeth  lips, tongue and gums when playing sports.',
      footer : '',    
    },
    repair : {
      title : 'Relines / Repairs',
      caption: '(Adjusting dentures for a better fit)',
      body : 'Dentures require regular maintenance for them to fit comfortably. Naturally, over time as the tissues start to shrink, you will notice your dentures becoming more loose. When this occurs, your denture may require a reline. Relining a denture involves re-fitting the tissue side of the denture to create a more comfortable and stable fit.',
      listTitle : 'Reasons you may need a reline:',
      list : [
                '-Loss of teeth (extractions)',
                '-Weight loss',
                '-Bone loss in upper or lower jaw',
                '-General physiological aging',
                '-Disease or illness',
      ],
      footer : '',
    }
};

function createList(list){

    let listItems = list.map((item) => 
        (<li key={item}><Typography variant='body1'>{item}</Typography></li>));

    return(<ul>{listItems}</ul>);
}

function ServicePage(props) {

    const classes = useStyles();

    //scroll to top
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
    let benefitList;

    if(('list' in serviceList[props.variant])){
        benefitList = createList(serviceList[props.variant]['list']);
    }

    function printSecondBody(){
        if(('body2' in serviceList[props.variant])){
            return (
                <Typography className={classes.body} variant='body1'>{serviceList[props.variant]['body2']}</Typography>
            );
        }
        return null;
    }

    function selectImage(variant){
        switch(variant) {
            case 'complete':
              return completeImage;
            case 'partial':
              return partialImage;
            case 'immediate':
                return immidiateImage;
            case 'implant':
                return implantImage;
            case 'guards':
                return mouthGuardImage;
            case 'repair':
                return repairImage;
            default:
              return null;
          }
    }

    return (
        <React.Fragment>
            <div className={classes.miniHeader}>
                <Typography className={classes.header}>Services</Typography> 
            </div>      
            <div className={classes.content}>
                <Typography className={classes.header3}>{serviceList[props.variant]['title']}</Typography>
                <Typography className={classes.header4}>{serviceList[props.variant]['caption']}</Typography>                
                <div className={classes.imageDiv}>
                    <div>
                        <img className={classes.image} src={selectImage(props.variant)} alt={props.variant} />
                    </div>
                    <div>
                        <Typography className={classes.body} variant='body1' >{serviceList[props.variant]['body']}</Typography>
                        <Typography className={classes.header5}>{serviceList[props.variant]['listTitle']}</Typography>
                        {benefitList}
                    </div>
                </div>
                
                <Typography className={classes.header3} style={
                    {margin: '15px 0px 0px 0px'}}>{serviceList[props.variant]['title2']}</Typography>

                { printSecondBody() }

                <Typography className={classes.header5} variant='body1'>{serviceList[props.variant]['footer']}</Typography>
            </div> 
        </React.Fragment>       
    )
}

export default ServicePage;