import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';
import ContactForm from './ui/ContactForm'
import GoogleMap from './ui/GoogleMap';
import Collage from './ui/Collage';

const useStyles = makeStyles((theme) => ({
    content:{
        minHeight: '45vh',    
        display: 'flex',
        flexDirection: 'column',    
        justifyContent: 'center',
        textAlign: 'center',
        padding: '30px 20vw',
        [theme.breakpoints.down("xs")]: {            
            padding: '0px 25px 20px 25px',
        }   
    },
    alterContent:{
        // backgroundColor: 'rgba(19,46,91,0.75)',
        backgroundColor: 'rgba(19,46,91,0.40)',

        color: 'white',   
        display: 'flex',
        flexDirection: 'column',    
        justifyContent: 'center',
        textAlign: 'left',
        padding: '30px 20vw',
        textShadow: '2px 2px 10px rgba(0,0,0,0.8)',
        [theme.breakpoints.down("xs")]: {  
            padding: '30px 25px',          
        }
    },
    imageContent:{
        minHeight: '40vh',  
        backgroundColor: 'white',  
        textAlign: 'center',  
        display: 'flex',
        flexDirection: 'row',   
        flexWrap: 'wrap',         
        justifyContent: 'space-evenly',
        padding: '30px 20vw',
        [theme.breakpoints.down("xs")]: {  
            padding: '15px 0px',          
            display: 'flex',
            flexDirection: 'column',    
            justifyContent: 'space-evenly',
        }
    },
    contactContent: {
        backgroundColor: 'white',
        color: 'white',   
        display: 'flex',
        flexDirection: 'column',    
        justifyContent: 'center',
        textAlign: 'justify',
        padding: '40px 20vw', 
        [theme.breakpoints.down("xs")]: {  
            padding: '15px 25px',    
                  
        }
    },
    header: {
        ...theme.typography.contentHeader,
        margin: '30px 15px',
        textShadow: '2px 2px 15px rgba(0,0,0,0.8)',
        [theme.breakpoints.down("xs")]: {            
            fontWeight: '700',
            fontSize: '2.65rem',
            margin: '30px 0px 10px 0px',
        }     
    },
    header2: {
        ...theme.typography.contentHeader,
        margin: '25px auto',
        color: '#132E5B',
        [theme.breakpoints.down("xs")]: {     
            fontSize: '2.45rem',
        } 
    },
    caption: {
        ...theme.typography.subtitle2,
        margin: '15px 15px',
        textShadow: '2px 2px 10px rgba(0,0,0,0.8)',
        fontSize: '1.55rem',
        [theme.breakpoints.down("xs")]: {            
            fontWeight: '400',
            fontSize: '1.15rem',
        }   
    },
  }));

function Home() {

    const classes = useStyles();
    
    return(
        <React.Fragment>
            <div className={classes.content}>
                <Typography className={classes.header}>Welcome to <span style={{ display: 'inline-block'}}>Pure Denture Clinic</span></Typography> 
                <Typography className={classes.caption}>Dedicated to Excellence. <span style={{ display: 'inline-block'}}>Dedicated to your Smile.</span></Typography>
            </div>    
            <div className={classes.imageContent}>
                <Collage></Collage>
            </div>
            <div className={classes.alterContent}>
                <Typography variant='body2' component='div'>    
                    <Box lineHeight={1.5}>
                    Our practice is a local denture clinic located in West Edmonton. At Pure Denture Clinic, we strive to provide you with all your denture needs. Whether you are looking to get a new denture or needing a repair, we offer a variety of personalized denture services. We believe in providing the highest quality of care for our patients. Our staff is committed to sharing our knowledge to ensure we find the solution you have been looking for.
                        <br/><br/>
                        
                        We provide:
                        <ul>
                            <li>Free Consultations</li>
                            <li>Wheelchair Accessibility</li>
                            <li>Direct Billing for Insurance Companies</li>
                            <li>Certified Denturist</li>
                            <li>On-Site Laboratory</li>
                            <li>Wide Range of Denture Services</li>
                            <li>Patient Parking</li>
                        </ul>
                        Contact us today for a complimentary consultation!
                    </Box>
                </Typography>     
            </div>

            <div className={classes.contactContent}>
                <Typography className={classes.header2} variant='h1'>Contact Us</Typography>
                <ContactForm/>
                <Typography className={classes.header2} variant='h1'>Visit Us</Typography>
                <GoogleMap/>
            </div>
        </React.Fragment>
    )
}

export default Home;