import React from 'react';
import { Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
// import logo from '../../images/PureLogo4.png';
import revisedLogo from '../../images/PureRevised2.png';

const useStyles = makeStyles((theme) => ({
    logoContainer: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    logo: {
    height: '4em',
    [theme.breakpoints.down("sm")]:{
        height: '3.75em'
    }
  }
}));

function LogoButton(props) {
    const classes = useStyles();
    return(
        <Button className={classes.logoContainer} disableRipple onClick={() => props.setZero()} component={Link} to="/">
            <img className={classes.logo} src={revisedLogo} alt=""></img>
        </Button>
    )
}

export default LogoButton;